import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>Fresh talent</h2>
      <p>
        We're always looking to showcase fresh talent at our events. If you would like to get involved, please drop us a line!
      </p>
    </section>
    <section id="contact">
      <h2>Contact us</h2>
      <dl className="alt">
        <dt>Email</dt>
        <dd>
          <a href="mailto:info@mixbprod.com">info@mixbprod.com</a>
        </dd>
        <dl className="alt">
          <dt>Location</dt>
          <dd>Newcastle, NSW, Australia</dd>
        </dl>
      </dl>
      <ul className="icons">
        <li>
          <a href="https://www.facebook.com/MixBproductions" className="icon fa-facebook alt" target="mixbfb">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://twitch.tv/mixbprod" className="icon fa-twitch alt" target="mixbtwitch">
            <span className="label">Twitch</span>
          </a>
        </li>
        <li>
          <a href="http://bit.ly/mbprod-youtube" className="icon fa-youtube alt" target="mixbyoutube">
            <span className="label">YouTube</span>
          </a>
        </li>
        {/**
        <li>
          <a
            href="https://github.com/codebushi/gatsby-starter-stellar"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a href="https://codebushi.com" className="icon fa-dribbble alt">
            <span className="label">Dribbble</span>
          </a>
        </li>
         */}
      </ul>
    </section>
    <p className="copyright">
      &copy; MB Productions. Design: <a href="https://html5up.net">HTML5 UP</a>.
    </p>
  </footer>
)

export default Footer
